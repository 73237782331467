import React, { useState } from 'react';
import { Drawer, Row, Col , Button, Form, Popconfirm } from 'antd';
import ButtonYp from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCommentAlt, faPhoneAlt, faEye } from '@fortawesome/pro-solid-svg-icons';
import Dragger from 'antd/lib/upload/Dragger';
import styles from "./UpdateCompanyRates.module.css";
import { notificationOk, isValidFileType, notificationError } from '../../utils/helpers';
import RatesApi from '../../Api/RatesApi';
import { CHANELLIST } from '../../utils/const';
import { useTranslation } from 'react-i18next';

const UpdateCompanyRates = ({visible, onClose, onUpdate, onViewRates}) => {

  const [t] = useTranslation("company");
  const {getRatestemplate} = RatesApi();

  const [form] = Form.useForm();

  const [ fileListSms, setFileListSms] = useState([]);
  const [ fileNameSms, setFileNameSms] = useState("");
  const [ fileListVoice, setFileListVoice] = useState([]);
  const [ fileNameVoice, setFileNameVoice] = useState("");

  const propsRatesSms = {
    onRemove: file => {
      const index = fileListSms.indexOf(file);
      const newFileList = fileListSms.slice();
      newFileList.splice(index, 1);
      setFileListSms(newFileList);
      setFileNameSms("");
    },
    beforeUpload: file => {

      if(!isValidFileType(file.type)) {
        let text = "El archivo {filename} no tiene el formato correcto";
        text = text.replace("{filename}", file.name);
        notificationError(text);
        setFileListSms([]);
        return false;
      }

      setFileListSms([file]);
      setFileNameSms(file.name);
      return false;
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if(!isValidFileType(file.type)) {
        let text = "El archivo {filename} no tiene el formato correcto";
        text = text.replace("{filename}", file.name);
        notificationError(text);
      }
    },
    fileList: fileListSms,
    accept: ".csv,.xlsx"
  };

  const propsRatesVoice = {
    onRemove: file => {
      const index = fileListVoice.indexOf(file);
      const newFileList = fileListVoice.slice();
      newFileList.splice(index, 1);
      setFileListVoice(newFileList);
      setFileNameVoice("");
    },
    beforeUpload: file => {

      if(!isValidFileType(file.type)) {
        let text = "El archivo {filename} no tiene el formato correcto";
        text = text.replace("{filename}", file.name);
        notificationError(text);
        setFileListVoice([]);
        return false;
      }

      setFileListVoice([file]);
      setFileNameVoice(file.name);
      return false;
    },
    onDrop(e) {
      const file = e.dataTransfer.files[0];
      if(!isValidFileType(file.type)) {
        let text = "El archivo {filename} no tiene el formato correcto";
        text = text.replace("{filename}", file.name);
        notificationError(text);
      }
    },
    fileList: fileListVoice,
    accept: ".csv,.xlsx"
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const dowloadRateTemplate = (channel) => {
    getRatestemplate(channel)
    .then(response => {
      dowloadTemplate(response);
    })
    .catch(error => {
      console.error(error);
    })
  }

  let dowloadTemplate = (url) => {
    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = url;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    // Remove link from body
    document.body.removeChild(link);
    setTimeout(() => {
      notificationOk(t("drawer-update-rates.button-download-template"))
    }, 4850);
  }

  const resetValues = () => {
    form.resetFields();
    setFileListSms([]);
    setFileListVoice([]);
    setFileNameSms("");
    setFileNameVoice("");
  }

  return (
    <Drawer
      title={t("drawer-update-rates.label-title")}
      placement="right"
      width={620}
      onClose={() => {
        resetValues();
        onClose();
      }}
      visible={visible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {t("drawer-update-rates.button-cancel")}
          </Button>
          <Popconfirm title={`Are you sure to create company?`}
              onConfirm={() => {
                form.validateFields()
                  .then(values => {
                    values.fileSms = fileListSms;
                    values.fileVoice = fileListVoice;
                    onUpdate(values);
                    resetValues();
                  })
                  .catch(info => {
                      console.log('Validate Failed:', info);
                  });
              }}
              okText="yes"
              cancelText="no"
          >
            <Button
              type="primary"
            >
              {t("drawer-update-rates.button-ok")}
            </Button>
          </Popconfirm>
        </div>
      }
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          initialState: false,
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <div>
              <div className={styles['file-template']}>
                <ButtonYp
                  type="link"
                  icon={<FontAwesomeIcon icon={ faCommentAlt } />}
                  onClick={() => dowloadRateTemplate(CHANELLIST.SMS.key)}
                >
                  {t("drawer-update-rates.label-sms-template")}
                </ButtonYp>
                <ButtonYp
                  id="sms_rates_views"
                  type="link"
                  icon={<FontAwesomeIcon icon={ faEye } />}
                  style={{marginRight: 0}}
                  onClick={() => onViewRates(CHANELLIST.SMS.key)}
                >
                </ButtonYp>
              </div>
              <div className={styles['file-dragger']}>
                <Form.Item label={t("drawer-update-rates.label-title-sms-tamplate")}>
                  <Form.Item name="draggerSms" 
                    valuePropName="fileListSms" 
                    getValueFromEvent={normFile}
                    noStyle
                  >
                    <Dragger {...propsRatesSms} name="files">
                      <Button
                        className={styles['file-dragger-button']} shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
                      />
                      <br />
                      <p className="ant-upload-text"><strong>{t("drawer-update-rates.label-drag-file-title")}</strong></p>
                      <p className="ant-upload-hint">{t("drawer-update-rates.label-drag-file-subtitle-one")}</p>
                      <p className="ant-upload-text" style={{ fontWeight: "bold"}}>
                        {fileNameSms}
                      </p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div>
              <div className={styles['file-template']}>
                <ButtonYp
                  type="link"
                  icon={<FontAwesomeIcon icon={ faPhoneAlt } />}
                  onClick={() => dowloadRateTemplate(CHANELLIST.VOICE.key)}
                >
                  {t("drawer-update-rates.label-voice-template")}
                </ButtonYp>
                <ButtonYp
                  id="voice_rates_views"
                  type="link"
                  icon={<FontAwesomeIcon icon={ faEye } />}
                  style={{marginRight: 0}}
                  onClick={() => onViewRates(CHANELLIST.VOICE.key)}
                >
                </ButtonYp>
              </div>
              <div className={styles['file-dragger']}>
                <Form.Item label={t("drawer-update-rates.label-title-voice-tamplate")}>
                  <Form.Item name="draggerVoice" 
                    valuePropName="fileListVoice" 
                    getValueFromEvent={normFile}
                    noStyle
                  >
                    <Dragger {...propsRatesVoice} name="files">
                      <Button
                        className={styles['file-dragger-button']} shape="circle" icon={<FontAwesomeIcon icon={ faUpload } />} size="large"
                      />
                      <br />
                      <p className="ant-upload-text"><strong>{t("drawer-update-rates.label-drag-file-title")}</strong></p>
                      <p className="ant-upload-hint">{t("drawer-update-rates.label-drag-file-subtitle-one")}</p>
                      <p className="ant-upload-text" style={{ fontWeight: "bold"}}>
                        {fileNameVoice}
                      </p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
      </Form>

    </Drawer>
  );
}

export default UpdateCompanyRates;