import { useContext } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BalanceApi from "../../Api/BalanceApi";
import TableBodyCustom from "../../components/TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTrCustom from "../../components/TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import TableCustom from "../../components/TableCustom/TableCustom";
import TableHeadCustom from "../../components/TableCustom/TableHeadCustom/TableHeadCustom";
import { AuthContext } from "../../contexts/authRedirect";
import useHeaderOptions from "../../Hooks/useHeaderOptions";
import useSpinner from "../../Hooks/useSpinner";
import TYPES from "../../reducers/types";
import CONSTANTS from "../../utils/const";
import { dateFormat } from "../../utils/helpers";
import styles from "./HistoryBalance.module.css";

const HistoryBalance = () => {

  const [ historyBalanceData, setHistoryBalanceData ] = useState([]);
  
  const { auth } = useContext(AuthContext);
  const { headerOptionsDispacth, headerOptionsState } = useHeaderOptions();
  const { spinnerDispacth } = useSpinner();

  const { GetHistoricTransferByCompany, GetHistoricTransferAdmin } = BalanceApi();

  const [t] = useTranslation("history-balance");

  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
    });

    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.HISTORY_BALANCE_OPTION,
      payload: TYPES.HEADER_OPTIONS.HISTORY_BALANCE_OPTION
    })

    getData(CONSTANTS.LIST.PERIOD[0].key)
      .then(resutl => {
        setHistoryBalanceData(resutl);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
        });
      })
      .catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
        });
      });

  }, [])

  useEffect(() => {
    if(headerOptionsState.model.selectedDate) {

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
      });

      getData(headerOptionsState.model.selectedDate)
      .then(resutl => {
        setHistoryBalanceData(resutl);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
        });
      }).catch(error => {
        console.error(error);
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
        });
      });
    }
  }, [headerOptionsState])

  const getData = async (selectedDate) => {
    let result = [];
    if(auth.role === CONSTANTS.ROLES.ADMIN) {
      result = await GetHistoricTransferAdmin(selectedDate);
    } else {
      result = await GetHistoricTransferByCompany(auth.companyId, selectedDate);
    }

    return result;
  }

  return (
    <div className={styles['contain']}>
      <div className={styles['table-container']}>
        <TableCustom>
          <TableHeadCustom>
            { auth.role === CONSTANTS.ROLES.ADMIN && (<th>{t("header-table-company")}</th>) }
            <th>{t("header-table-source-account")}</th>
            <th>{t("header-table-destination-account")}</th>
            <th>{t("header-table-assign-credit")}</th>
            <th>{t("header-table-asignation-date")}</th>
          </TableHeadCustom>
          <TableBodyCustom>
            {
              historyBalanceData.map(balance => (
                <TableBodyTrCustom key={balance.id}>
                  { auth.role === CONSTANTS.ROLES.ADMIN && (<td>{balance.company}</td>) }
                  <td>{balance.accountFrom}</td>
                  <td>{balance.accountTo}</td>
                  <td>{balance.amount}</td>
                  <td>{dateFormat(balance.transferDate)}</td>
                </TableBodyTrCustom>
              ))
            }
          </TableBodyCustom>
        </TableCustom>
      </div>
    </div>
  );
}
 
export default HistoryBalance;