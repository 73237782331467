import React, { useState } from 'react';
import { Drawer, Row, Col, Button, Form, Input, Select, Popconfirm } from 'antd';
import useCurrency from '../../Hooks/useCurrency';
import ButtonYp from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faHandPointRight, faPhoneAlt, faUpload,faFileDownload } from '@fortawesome/pro-solid-svg-icons';
import HelperButton from '../HelperButton/HelperButton';
import { getGradientColor, purpleColor } from '../../utils/colorsCss';
import Dragger from 'antd/lib/upload/Dragger';
import styles from "./CreateCompany.module.css";
import { getTypeFile, isValidFileType, notificationError } from '../../utils/helpers';
import RatesApi from '../../Api/RatesApi';
import { CHANELLIST } from '../../utils/const';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const CreateCompany = ({ visible, timezones, onClose, onCreate }) => {

    const [t] = useTranslation("company");
    const { getRatestemplate } = RatesApi();

    // Obtenemos los timezone de redux
    const { currencies } = useCurrency();
    //const { timezones } = useTimezone();

    const [form] = Form.useForm();

    const [fileListSms, setFileListSms] = useState([]);
    const [fileNameSms, setFileNameSms] = useState("");
    const [fileListVoice, setFileListVoice] = useState([]);
    const [fileNameVoice, setFileNameVoice] = useState("");

    const propsRatesSms = {
        onRemove: file => {
            const index = fileListSms.indexOf(file);
            const newFileList = fileListSms.slice();
            newFileList.splice(index, 1);
            setFileListSms(newFileList);
            setFileNameSms("");
            //onRemoveFile(null);
        },
        beforeUpload: file => {

            if (!isValidFileType(file.type)) {
                let text = "El archivo {filename} no tiene el formato correcto";
                text = text.replace("{filename}", file.name);
                notificationError(text);
                setFileListSms([]);
                return false;
            }

            setFileListSms([file]);
            setFileNameSms(file.name);
            //setContactSelected(null);

            //let fileType = getTypeFile(file.type);
            //onUploadFile(file, fileType);
            return false;
        },
        onDrop(e) {
            const file = e.dataTransfer.files[0];
            if (!isValidFileType(file.type)) {
                let text = "El archivo {filename} no tiene el formato correcto";
                text = text.replace("{filename}", file.name);
                notificationError(text);
            }
        },
        fileList: fileListSms,
        accept: ".csv,.xlsx"
    };

    const propsRatesVoice = {
        onRemove: file => {
            const index = fileListVoice.indexOf(file);
            const newFileList = fileListVoice.slice();
            newFileList.splice(index, 1);
            setFileListVoice(newFileList);
            setFileNameVoice("");
        },
        beforeUpload: file => {

            if (!isValidFileType(file.type)) {
                let text = "El archivo {filename} no tiene el formato correcto";
                text = text.replace("{filename}", file.name);
                notificationError(text);
                setFileListVoice([]);
                return false;
            }

            setFileListVoice([file]);
            setFileNameVoice(file.name);
            return false;
        },
        onDrop(e) {
            const file = e.dataTransfer.files[0];
            if (!isValidFileType(file.type)) {
                let text = "El archivo {filename} no tiene el formato correcto";
                text = text.replace("{filename}", file.name);
                notificationError(text);
            }
        },
        fileList: fileListVoice,
        accept: ".csv,.xlsx"
    };

    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const dowloadRateTemplate = (channel, fileType) => {
        console.log('Downloading template for channel:', channel);
    console.log('File type requested:', fileType);
        getRatestemplate(channel, fileType)
            .then(response => {
                console.log('Response URL:', response);
                dowloadTemplate(response);
            })
            .catch(error => {
                console.error(error);
            })
    }

    let dowloadTemplate = (url) => {
        // Create a link element
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = url;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    }

    return (
        <Drawer
            title="Create Company"
            placement="right"
            width={620}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            visible={visible}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                        {t("drawer-create-company.button-cancel")}
                    </Button>
                    <Popconfirm title={`Are you sure to create company?`}
                        onConfirm={() => {
                            form.validateFields()
                                .then(values => {
                                    values.fileSms = fileListSms;
                                    values.fileVoice = fileListVoice;
                                    onCreate(values);
                                    form.resetFields();
                                    setFileListSms([]);
                                    setFileListVoice([]);
                                })
                                .catch(info => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                        okText="yes"
                        cancelText="no"
                    >
                        <Button
                            type="primary"
                        >
                            {t("drawer-create-company.button-ok")}
                        </Button>
                    </Popconfirm>
                </div>
            }
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    initialState: false,
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("drawer-create-company.label-company-name")}
                            rules={[{ required: true, message: 'Please enter company name' }]}
                        >
                            <Input placeholder={t("drawer-create-company.placeholder-company-name")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label={
                              <span>
                                  {t("drawer-create-company.label-user-company-email")}
                                  <HelperButton
                                       message={t("drawer-update-rates.mail-tooltip")}
                                      color={getGradientColor(purpleColor)}
                                  />
                              </span>
                            }  
                            rules={[{ type: 'email', required: true, message: 'Please enter user company email' }]}
                        >
                            <Input placeholder={t("drawer-create-company.placeholder-user-company-email")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="firstName"
                            label={t("drawer-create-company.label-first-name")}
                            rules={[{ required: true, message: 'Please enter the first name' }]}
                        >
                            <Input placeholder={t("drawer-create-company.placeholder-first-name")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="lastName"
                            label={t("drawer-create-company.label-last-name")}
                            rules={[{ required: true, message: 'Please enter the last name' }]}
                        >
                            <Input placeholder={t("drawer-create-company.placeholder-last-name")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="displayName"
                            label={t("drawer-create-company.label-display-name")}
                            rules={[{ required: true, message: 'Please enter the display name' }]}
                        >
                            <Input placeholder={t("drawer-create-company.placeholder-display-name")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="phone"
                            label={t("drawer-create-company.label-phone")}
                            rules={[{ required: true, message: 'Please enter the phone number' }]}
                        >
                            <Input placeholder={t("drawer-create-company.placeholder-phone")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="timezone"
                            label={
                              <span>
                                  {t("drawer-create-company.label-time-zone")}
                                  <HelperButton
                                       message={t("drawer-update-rates.time-zone-tooltip")}
                                      color={getGradientColor(purpleColor)}
                                  />
                              </span>
                            }
                            rules={[{ required: true, message: 'Please select an time zone' }]}
                        >
                            <Select 
                                showSearch
                                placeholder={t("drawer-create-company.placeholder-time-zone")}                              
                                >
                                {
                                    timezones.map(item =>
                                        <Option key={item.key} value={item.value}>{item.value}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="currency"
                            label={t("drawer-create-company.label-currency")}
                            rules={[{ required: true, message: 'Please select a currency' }]}
                        >
                            <Select placeholder={t("drawer-create-company.placeholder-currency")}>
                                {
                                    currencies.map(item =>
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <div>
                            <div className={styles['title-upload-file']}>
                                <div className={styles['title-text']}>
                                    <FontAwesomeIcon icon={faCommentAlt} />
                                    <p>{t("drawer-create-company.label-title-sms-template")}</p>
                                </div>
                                <HelperButton
                                    message={t("drawer-update-rates.label-sms-template-tooltip")}
                                    color={getGradientColor(purpleColor)}
                                />
                            </div>
                            <div className={styles['file-dragger']}>
                                <div className={styles['box-button']}>
                                    <ButtonYp
                                        type="link"                                  
                                        onClick={() => dowloadRateTemplate(CHANELLIST.SMS.key, 'csv')}
                                        className={styles['text-download']}                                        
                                    >
                                        <FontAwesomeIcon icon={faFileDownload}/>
                                        {t("drawer-create-company.label-sms-template")}
                                    </ButtonYp>
                                    <ButtonYp
                                        type="link"                                  
                                        onClick={() => dowloadRateTemplate(CHANELLIST.SMS.key, 'xslx')}
                                        className={styles['text-download']}
                                    >
                                        <FontAwesomeIcon icon={faFileDownload}/>
                                        {t("drawer-create-company.label-sms-template-xslx")}
                                    </ButtonYp>
                                </div>
                                <Form.Item>
                                    <Form.Item name="draggerSms"
                                        valuePropName="fileListSms"
                                        getValueFromEvent={normFile}
                                        noStyle
                                        rules={[{ required: true, message: 'Please upload rates sms' }]}
                                    >
                                        <Dragger {...propsRatesSms} name="files">
                                            <Button
                                                className={styles['file-dragger-button']} shape="circle" icon={<FontAwesomeIcon icon={faUpload} />} size="large"
                                            />
                                            <br />
                                            <p className="ant-upload-text"><strong>{t("drawer-create-company.label-drag-file-title")}</strong></p>
                                            <p className="ant-upload-hint">{t("drawer-create-company.label-drag-file-subtitle-one")}</p>
                                            <p className="ant-upload-text" style={{ fontWeight: "bold" }}>
                                                {fileNameSms}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <div>
                            <div className={styles['title-upload-file']}>                               
                                <div className={styles['title-text']}>
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                    <p>{t("drawer-create-company.label-title-voice-template")}</p>
                                </div>
                                <HelperButton
                                    message={t("drawer-update-rates.label-voice-template-tooltip")}
                                    color={getGradientColor(purpleColor)}
                                />
                            </div>
                            <div className={styles['file-dragger']}>
                                <div className={styles['box-button']}>
                                    <ButtonYp
                                        type="link"                                 
                                        onClick={() => dowloadRateTemplate(CHANELLIST.VOICE.key, 'csv')}
                                        className={styles['text-download']}
                                    >
                                        <FontAwesomeIcon icon={faFileDownload}/>
                                        {t("drawer-create-company.label-voice-template")}
                                    </ButtonYp>
                                    <ButtonYp
                                        type="link"                                  
                                        onClick={() => dowloadRateTemplate(CHANELLIST.VOICE.key, 'xslx')}
                                        className={styles['text-download']}
                                    >
                                        <FontAwesomeIcon icon={faFileDownload}/>
                                        {t("drawer-create-company.label-voice-template-xslx")}
                                    </ButtonYp>
                                </div>
                                <Form.Item>
                                    <Form.Item name="draggerVoice"
                                        valuePropName="fileListVoice"
                                        getValueFromEvent={normFile}
                                        noStyle
                                        rules={[{ required: true, message: 'Please upload rates voice' }]}
                                    >
                                        <Dragger {...propsRatesVoice} name="files">
                                            <Button
                                                className={styles['file-dragger-button']} shape="circle" icon={<FontAwesomeIcon icon={faUpload} />} size="large"
                                            />
                                            <br />
                                            <p className="ant-upload-text"><strong>{t("drawer-create-company.label-drag-file-title")}</strong></p>
                                            <p className="ant-upload-hint">{t("drawer-create-company.label-drag-file-subtitle-one")}</p>
                                            <p className="ant-upload-text" style={{ fontWeight: "bold" }}>
                                                {fileNameVoice}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>

        </Drawer>
    );
}

export default CreateCompany;